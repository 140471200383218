import { gql } from 'graphql-request'

export const CreatePtv2PlannedTrail = gql`
  mutation CreatePtv2PlannedTrail($plannedTrail:CreatePtv2PlannedTrailInput!) {
    createPtv2PlannedTrail(input: $plannedTrail) {
      id
      title
      clientId
      createdAt
      updatedAt
      public
      thumbnailUrl
      description
      plannedToHikeOn
      mapStyles {
        name
        id
        description
        zoomRange
        copyright
        styleFile
        image
        proPlus
      }
      images {
        id
        file {
          lgUrl
        }
      }
      distanceMeters
      elevationDataMappedAt
      elevationGainMeters
      errors {
        errors
        fieldName
      }
      calculatedDurationMilliseconds
      calculatedDurationMinutes
      shareableUrl
      geojsonFeatureCollection
      startAddress {
        region
        regionCountry
        city
        cityDistrict
        country
        name
      }
      originalPlannedTrail {
        title
        thumbnailUrl
        startAddress {
          regionCountry
        }
      }
      trail {
        id
        name
        trailDistance
        durationString
        shortAddress
        staticMapImage
        jumboImage {
          id
          url
          metadata
        }
      }
    }
  }
`;

export const UpdatePtv2PlannedTrail = gql`
  mutation UpdatedPtv2PlannedTrail($plannedTrail:UpdatePtv2PlannedTrailInput!) {
    updatePtv2PlannedTrail(input: $plannedTrail) {
      id
      title
      clientId
      createdAt
      updatedAt
      public
      thumbnailUrl
      description
      plannedToHikeOn
      mapStyles {
        name
        id
        description
        zoomRange
        copyright
        styleFile
        image
        proPlus
      }
      images {
        id
        file {
          lgUrl
        }
      }
      distanceMeters
      elevationDataMappedAt
      elevationGainMeters
      errors {
        errors
        fieldName
      }
      calculatedDurationMilliseconds
      calculatedDurationMinutes
      shareableUrl
      geojsonFeatureCollection
      startAddress {
        region
        regionCountry
        city
        cityDistrict
        country
        name
      }
      originalPlannedTrail {
        title
        thumbnailUrl
        startAddress {
          regionCountry
        }
      }
      trail {
        id
        name
        trailDistance
        durationString
        shortAddress
        staticMapImage
        jumboImage {
          id
          url
          metadata
        }
      }
    }
  }
`;

export const CopyCuratedTrailToPtv2PlannedTrail = gql`
    mutation CopyCuratedTrailToPtv2PlannedTrail($trail: CopyCuratedTrailToPtv2PlannedTrailInput!) {
      copyCuratedTrailToPtv2PlannedTrail(input: $trail) {
        id
        title
        clientId
        createdAt
        updatedAt
        public
        thumbnailUrl
        description
        plannedToHikeOn
        mapStyles {
          name
          id
          description
          zoomRange
          copyright
          styleFile
          image
          proPlus
        }
        images {
          id
          file {
            lgUrl
          }
        }
        distanceMeters
        elevationDataMappedAt
        elevationGainMeters
        errors {
          errors
          fieldName
        }
        calculatedDurationMilliseconds
        calculatedDurationMinutes
        shareableUrl
        geojsonFeatureCollection
        startAddress {
          region
          regionCountry
          city
          cityDistrict
          country
          name
        }
        originalPlannedTrail {
          title
          thumbnailUrl
          startAddress {
            regionCountry
          }
        }
        trail {
          id
          name
          trailDistance
          durationString
          shortAddress
          staticMapImage
          jumboImage {
            id
            url
            metadata
          }
        }
      }
    }
`;

export const SearchUsersPlannedTrails = gql`
  query SearchUsersPlannedTrails($string: String!) {
    ptv2PlannedTrailsTextSearch(string: $string) {
      id
      title
      distanceMeters
      thumbnailUrl
    }
  }
`;

export const DestroyPtv2PlannedTrail = gql`
  mutation DestroyPtv2PlannedTrail($plannedTrail: DestroyPtv2PlannedTrailInput!) {
    destroyPtv2PlannedTrail(input: $plannedTrail) {
      id
      errors {
        __typename
        fieldName
        errors
      }
    }
  }
`;

export const GetPtv2PlannedTrail = gql`
  query GetPtv2PlannedTrail($id: ID!) {
    ptv2PlannedTrailById(id: $id) {
      id
      title
      clientId
      createdAt
      updatedAt
      public
      thumbnailUrl
      description
      plannedToHikeOn
      images {
        id
        file {
          lgUrl
        }
      }
      distanceMeters
      elevationDataMappedAt
      elevationGainMeters
      errors {
        errors
        fieldName
      }
      calculatedDurationMilliseconds
      calculatedDurationMinutes
      shareableUrl
      geojsonFeatureCollection
      startAddress {
        region
        regionCountry
        city
        cityDistrict
        country
        name
      }
    }
  }
`;
